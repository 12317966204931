import {useLocation, useNavigate, useNavigationType, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Backdrop, CircularProgress, Skeleton, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SnackbarWithCustomBackground from "./snackbar";
import icLeftArrow from "../assets/icLeftArrow.svg";
import icRightArrow from "../assets/icRightArrow.svg";
import {createCalendarEvent, firebaseAuth, getFreeTimeSlotsv2cl, updateRdv} from "../firebase";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";
import {
    addDurationToTime,
    cleanLocalStorage,
    loadFromLocalStorage,
    parseFrenchDateWithTimezone,
    removeFromLocalStorage
} from "./utils";
import {serverTimestamp} from "@firebase/firestore";
import ReactGA from "react-ga4";

const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};
// Function to format the date in the desired format
const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
function formatDate(date) {
    const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const monthNames = Object.keys(monthMap);
    return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}
function formatTime(input) {
    const date = new Date(input);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}h${minutes}`;
}

export default function RdvTime() {



    const { id, id2 } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const location = useLocation();
    const stateData = location.state;
    const [displayPage, setDisplayPage] = useState(null);

    //**

    const choiceLoc = stateData?.choice;
    const monthLoc = stateData?.month;
    const yearLoc = stateData?.year;
    const dateString = choiceLoc + " " + monthLoc + " " + yearLoc;
    const dateComponents = dateString.split(" ");
    const day = parseInt(dateComponents[1]);
    const monthIndex = monthMap[dateComponents[2]];
    const year = parseInt(dateComponents[3]);
    const [currentDate, setCurrentDate] = useState(new Date(year, monthIndex, day));
    const [displayDate, setDisplayDate] = useState(formatDate(new Date(year, monthIndex, day)));
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [isGoogle, setIsGoogle] = useState(false);
    const [dateItems, setDateItems] = useState([]);
    const [prestaDura, setPrestaDura] = useState(null);
    const [prestaBt, setPrestaBt] = useState(null);
    const [prestaObject, setPrestaObject] = useState(null);
    const [agendaObject, setAgendaObject] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [blockButtons, setBlockButtons] = useState(false);
    const [showRealBackdrop, setShowRealBackdrop] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const rdvId = queryParams.get('rdvId');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {

        if (loadFromLocalStorage("depositResult")) {
            navigate('/'+id);
        } else {
            cleanLocalStorage();
        }

        const unregisterAuthObserver = onAuthStateChanged(  firebaseAuth,  async ( user) => {
            if (!(user && user.isAnonymous)) {
                try {
                    await signInAnonymously(firebaseAuth);
                } catch (e) {
                    console.log("Auth error => ", e.message);
                    setDisplayPage(false);
                }
            }
            if (user){
                if (stateData!=null && stateData.userObject!=null && stateData.prestaObject!=null && stateData.agData!=null){
                    const fetchData = async () => {
                        //firebase.functions().useEmulator("127.0.0.1", 5001);
                        try {
                            /*const currentDate = new Date();
                            const nextDay = new Date(currentDate);
                            nextDay.setDate(currentDate.getDate() + 1);*/
                            const agendaData = stateData.agData;
                            setAgendaObject(agendaData);
                            const isGoogleEnabled = agendaData.googleAGCode;
                            setIsGoogle(isGoogleEnabled)
                            const prestaData = stateData.prestaObject;
                            setPrestaObject(prestaData);
                            const prestaDuration = prestaData.prestaDuration;
                            const prestaBreak = prestaData.breakDuration;
                            setPrestaDura(prestaDuration);
                            setPrestaBt(prestaBreak);
                            let skipDatabaseSearch = true;
                            for (let daystr of prestaData.selectedDays){
                                if (dayNames[currentDate.getDay()]===daystr){
                                    skipDatabaseSearch=false;
                                    break;
                                }
                            }
                            if (!skipDatabaseSearch){
                                // Get the local timezone offset in minutes, add it as abs - this is a workaround to ship fast
                                const offsetInMinutes = currentDate.getTimezoneOffset();
                                const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                                const adjustedDate = new Date(currentDate.getTime() + Math.abs(offsetInMilliseconds));
                                const result = await getFreeTimeSlotsv2cl({
                                    inputDate : adjustedDate,
                                    isGoogleSync : agendaData.googleAGCode,
                                    prestaDuration: prestaDuration,
                                    prestaBreak : prestaBreak,
                                    uid : agendaData.uid
                                });
                                const array = [];
                                if (result.data.length>0){
                                    for (let res of result.data){
                                        array.push(formatTime(res.start))
                                    }

                                }
                                setDateItems(array);
                                setDisplayPage(true);
                            } else {
                                setDateItems([]);
                                setDisplayPage(true);
                            }
                        } catch (error) {
                            console.error('Error calling function:', error.message);
                            setDisplayPage(false);
                        }
                    };
                    await fetchData();
                } else {
                    setDisplayPage(false);
                }
            }
        });


        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const navigatePreviousDay = async () => {
        setBlockButtons(true);
        setShowBackdrop(true);
        const previousDay = new Date(currentDate);
        previousDay.setDate(currentDate.getDate() - 1);
        setCurrentDate(previousDay);
        const newDateString = formatDate(previousDay);
        setDisplayDate(newDateString);

        //Skip db search if presta unavailable this day
        let skipDatabaseSearch = true;
        for (let daystr of prestaObject.selectedDays){
            if (dayNames[previousDay.getDay()]===daystr){
                skipDatabaseSearch=false;
                break;
            }
        }
        if (skipDatabaseSearch){
            setDateItems([]);
            setShowBackdrop(false);
            setBlockButtons(false)
            return;
        }
        const offsetInMinutes = previousDay.getTimezoneOffset();
        const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
        const adjustedDate = new Date(previousDay.getTime() + Math.abs(offsetInMilliseconds));
        try {
            const result = await getFreeTimeSlotsv2cl({
                inputDate : adjustedDate,
                isGoogleSync : isGoogle,
                prestaDuration: prestaDura,
                prestaBreak : prestaBt,
                uid : stateData.agData.uid
            });
            const array = []
            if (result.data.length>0){
                for (let res of result.data){
                    array.push(formatTime(res.start))
                }
            }
            setDateItems(array);
        } catch (e) {
            console.log("Error ", e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous. ", e.message);
        }


        setShowBackdrop(false);
        setBlockButtons(false);
    }


    const navigateNextDay = async () => {
        setBlockButtons(true);
        setShowBackdrop(true)
        const nextDay = new Date(currentDate);
        nextDay.setDate(currentDate.getDate() + 1);
        setCurrentDate(nextDay);
        const newDateString = formatDate(nextDay);
        setDisplayDate(newDateString);

        //Skip db search if presta unavailable this day
        let skipDatabaseSearch = true;
        for (let daystr of prestaObject.selectedDays){
            if (dayNames[nextDay.getDay()]===daystr){
                skipDatabaseSearch=false;
                break;
            }
        }
        if (skipDatabaseSearch){
            setDateItems([]);
            setShowBackdrop(false);
            setBlockButtons(false);
            return;
        }

        const offsetInMinutes = nextDay.getTimezoneOffset();
        const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
        const adjustedDate = new Date(nextDay.getTime() + Math.abs(offsetInMilliseconds));
        try {

            const result = await getFreeTimeSlotsv2cl({
                inputDate : adjustedDate,
                isGoogleSync : isGoogle,
                prestaDuration: prestaDura,
                prestaBreak : prestaBt,
                uid : stateData.agData.uid
            });
            const array = []
            if (result.data.length>0){
                for (let res of result.data){
                    array.push(formatTime(res.start))
                }

            }
            setDateItems(array);
        } catch (e) {
            console.log(e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous. ", e.message);
        }
        setBlockButtons(false);
        setShowBackdrop(false)
    }


    const handleCardClick = (index) => {
        setSelectedItem((prevSelected) => (prevSelected === index ? null : index));
    };

    const navigateBack = async () => {
        navigate(-1);
    }

    const navigateToFillData = async () => {

        if (stateData?.rdvData?.isRescheduled){
            handleOpenSnackbar("Ce rendez-vous a déjà été déplacé !");
            return;
        }

        if (rdvId && stateData.rdvData.documentId && rdvId===stateData.rdvData.documentId && selectedItem!==null){

            setShowRealBackdrop(true);
            // this is rescheduled!

            const startDateStr = displayDate + " " + dateItems[selectedItem];
            const [date,[day,month,year]] = parseFrenchDateWithTimezone(startDateStr, stateData.userObject?.timezone);

            let endDateClient;
            let endDate;
            try {
                endDateClient = addDurationToTime(prestaObject.prestaDuration,date);
                endDate = addDurationToTime(prestaObject.breakDuration,endDateClient);
            } catch (e){
                handleOpenSnackbar("Quelque chose s'est mal passé, recommencez ou contactez nous.")
                console.log(e.message);
                return;
            }

            let dbObject = {
                dateStr : startDateStr,
                timeStr : dateItems[selectedItem],
                start : date,
                end : endDate,
                endDateClient: endDateClient,
                isCanceled : false,
                day : day,
                month : month,
                year : year,
                timestamp : serverTimestamp(),
                isGoogle : agendaObject?.googleAGCode,
                isValidated : true,
                documentId: rdvId,
                isRescheduled :true,
                prestaSnapshot : stateData.rdvData.prestaSnapshot
            }

            try {
                const currentDate = new Date(dbObject.year, dbObject.month, dbObject.day);
                const offsetInMinutes = currentDate.getTimezoneOffset();
                const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
                const adjustedDate = new Date(currentDate.getTime() + Math.abs(offsetInMilliseconds));
                const status = await updateRdv({dataToUpdate:dbObject, inputDate:adjustedDate});
                if (status==="blocked"){
                    handleOpenSnackbar("Ce créneau a été réservé à l'instant, essayez de choisir un autre créneau.");
                    setShowRealBackdrop(false);
                    return;
                }

                ReactGA.event({
                    category: "rdvclient",
                    action: "validate",
                    value: 1,
                    nonInteraction: true,
                    transport: "xhr",
                });

            } catch (e){
                console.log("Error => ", e.message);
                handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous.");
                setShowRealBackdrop(false);
                return;
            }

            if (agendaObject?.googleAGCode){
                try {
                    //needs to be base32 encoded to be added as calendarId - this allow binding to "rdv" collection

                    const encodedId = Array.from(new TextEncoder().encode(String(rdvId)))
                        .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                        .join('')
                        .toLowerCase();
                    // That is a way to decode, case preserved, for later use
                    /*
                    const decodedId = new TextDecoder().decode(
                         new Uint8Array(encodedId.match(/.{1,2}/g).map(chunk => parseInt(chunk, 32)))
                     );
                     console.log("decoded => ", decodedId);*/

                    let questionsString = "";

                    if (Array.isArray(stateData?.rdvData?.answers)) {
                        for (let answer of stateData?.rdvData?.answers){
                            questionsString += "Question : " + (answer?.question || "N/A") + "\n" +
                                "Réponse : " + (answer?.answer || "N/A") + "\n";
                        }
                    }

                    await createCalendarEvent({
                        isRescheduled:true,
                        summary: prestaObject.title,
                        startDateTime: date.toISOString(),
                        endDateTime: endDateClient.toISOString(),
                        docId : encodedId.toLowerCase(),
                        uid : stateData.prestaObject.uid,
                        description: "Client : " + stateData.rdvData.custName + "\n" +
                            "Email : " + stateData.rdvData.custAddress + "\n" +
                            "Téléphone : " + stateData.rdvData.custPhone + "\n" +
                            "Page de réservation : https://my.faismoibg.com/rdvdetails/" + rdvId + " \n" +
                            "Prix : " + stateData?.prestaObject?.price + "\n" + questionsString
                    })
                } catch (e) {
                    console.log(e.message);
                    console.error(e);
                }
            }

            navigate("/"+id+"/rdvstatus/"+stateData.rdvData.documentId);

        } else if (selectedItem!=null){

            navigate("/"+id+"/rdvinfo/"+id2,
                {state:
                        {
                            prestaObject : prestaObject,
                            agendaObject : agendaObject,
                            selectedItem : dateItems[selectedItem],
                            userObject : stateData.userObject,
                            date : displayDate,
                        }
                });

        } else {
            handleOpenSnackbar('Vous devez séléctionner une date pour continuer.');
        }

        /*if (selectedItem!=null){
            //navigate(`/appointday/${id}?choice=${choice}&month=${displayMonth}&year=${displayYear}`);
            const prestaObjectStr = encodeURIComponent(JSON.stringify(prestaObject));
            const agendaObjectStr = encodeURIComponent(JSON.stringify(agendaObject));
            const selectedItemStr = encodeURIComponent(JSON.stringify(dateItems[selectedItem]));
            const selectedDateStr = encodeURIComponent(JSON.stringify(displayDate));

            navigate("/appointconfirm/"+id + `?prestaObject=${prestaObjectStr}&agendaObject=${agendaObjectStr}&selectedItem=${selectedItemStr}&date=${selectedDateStr}`);
        } else {
            handleOpenSnackbar('Vous devez séléctionner une date pour continuer.');
        }*/

    }

    return (
        <div className="sub-container">

            {displayPage===null ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"10px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                displayPage ? (
                    <div className="sub-container-no-top" >
                        <span className="title-style">Séléctionner une heure</span>
                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginTop:"10px"}}>
                            <IconButton disabled={blockButtons} onClick={navigatePreviousDay}
                                        variant="contained" style={{ color:"white", display:"flex"}}>
                                <img src={icLeftArrow} style={{width:"40px", height:"40px"}}/>
                            </IconButton>
                            <div style={{fontWeight:"700", display:"flex", alignContent:"center", alignItems:"center", justifyContent:"center", textAlign:"center"}}>
                                {displayDate}
                            </div>
                            <IconButton disabled={blockButtons} onClick={navigateNextDay}
                                        variant="contained" style={{ color:"white", display:"flex"}}>
                                <img src={icRightArrow} style={{width:"40px", height:"40px"}}/>
                            </IconButton>
                        </div>

                        {showBackdrop && (
                            <div style={{display:"flex", width:"100%", flexDirection:"column",
                                justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                                <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                                <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                                <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            </div>
                        )}

                        <div className="date-layout">
                            { !showBackdrop && dateItems.map((date, index) => (
                                <div key={index}
                                     className="date-card"
                                     onClick={() => handleCardClick(index)}

                                     style={{background:
                                             selectedItem === index
                                                 ? "linear-gradient(100deg, #FF0844 0%, #4808FF 100%)"
                                                 : "white",
                                         color: selectedItem === index
                                             ? "white"
                                             : "black",
                                         cursor:"pointer"
                                     }}
                                >
                                    <div style={{margin:"20px"}}>
                                        {date}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {dateItems.length===0 && !showBackdrop && (
                            <div className="date-card" style={{width:"100%"}}>
                                <div style={{margin:"20px"}}>
                                    <span>Aucun créneau disponible ce jour</span>
                                </div>
                            </div>
                        )}

                        <div className="button-container">
                            <Button disabled={dateItems.length===0} onClick={navigateToFillData} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    Valider ce créneau
                                </Typography>
                            </Button>
                        </div>

                        <div className="button-container" style={{marginTop:"15px"}}>
                            <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                                <Typography variant="button" style={{ textTransform: 'none' }}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>


                    </div>

                ) : (
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                            alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            Cette page n'existe pas  🤷
                                        </span>
                        </div>
                    </div>
                )
        )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showRealBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />

        </div>
    )
}