import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import SnackbarWithCustomBackground from "./snackbar";
import {Gallery} from "./carouselpresta";
import Button from "@mui/material/Button";
import pinMapGrey from "../assets/ph_map-pin_grey.png";
import dollaIc from "../assets/dollaIc.svg";
import icContact from "../assets/icContact.svg";
import {firebaseAuth, getFreeTimeSlotsv2cl, getFreeTimeSlotsv2clbreak, getPrest, remTempRes} from "../firebase";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";
import ContactDrawer from "./contactdrawer";
import eyeMap from "../assets/eyemap.svg";
import {cleanLocalStorage, fetchLatLong, loadFromLocalStorage, removeFromLocalStorage} from "./utils";
import {MapComp} from "./map";

function durationFormatMin(duration){
    const [hours, minutes] = duration.split('h');
    return parseInt(hours) === 0 ? `${minutes} min` : `${duration}`;
}
export default function Details() {


    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const { id, id2 } = useParams();
    const [prestaData, setPrestaData] = useState({});
    const [userObject, setUserObject] = useState({});
    const [COK, setCOK] = useState(false);
    const [displayPage, setDisplayPage] = useState(null);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [isContinueDialogOpen, setIsContinueDialogOpen] = useState(false);
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    //comment for hash
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = onAuthStateChanged(  firebaseAuth,  async ( user) => {
            if (!(user && user.isAnonymous)) {
                try {
                    await signInAnonymously(firebaseAuth);
                } catch (e) {
                    console.log("Auth error => ", e.message);
                    setDisplayPage(false);
                }
            }
            if (user){
                //warmup
                getFreeTimeSlotsv2cl({isWarmUp:true}).catch((e)=>console.log(e));
                getFreeTimeSlotsv2clbreak({isWarmUp:true}).catch((e)=>console.log(e));

                const fetchData = async () => {
                    try {
                        /*const url = `https://us-central1-faismoibg-app.cloudfunctions.net/getPresta?id=${id2}&uid=${id}`;
                        const response = await axios.get(url);*/
                        const response = await getPrest({id:id2, pageId:id});
                        if (response?.data?.success){
                            //display page
                            setPrestaData(response.data.prestaData);
                            setUserObject(response.data.userData);
                            setCOK(response.data.countOK);
                            if (response.data?.prestaData?.selectedAddress?.length>2 && response.data?.prestaData?.isAddressVisible){
                                try {
                                    const { latitude, longitude } = await fetchLatLong(response.data.prestaData.selectedAddress);
                                    setLat(latitude);
                                    setLong(longitude);
                                } catch (e) {
                                    console.log(e.message);
                                }
                            }
                            if (response.data.prestaData?.imageLinks[0]?.length<1){
                                setPrestaData({
                                    ...response.data.prestaData,
                                    imageLinks:["https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."]
                                });
                            }
                            setDisplayPage(true);
                        } else {
                            setDisplayPage(false);
                        }
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                };
                await fetchData();
            }
        });


        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [prestaObject, setPrestaObject] = useState({});

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const navigateHome = async () => {
        navigate("/" + id)
    }

    const handleRdv = async () => {
        if (COK){
            const depositResult = loadFromLocalStorage("depositResult");
            if (depositResult){
                setIsContinueDialogOpen(true);
                return;
            }
            const stateData = {
                prestaObj: prestaData,
                userObj: userObject,
            };

            navigate("/"+id+"/rdvdate/"+id2, {
                state:stateData
            });
        } else {
            handleOpenSnackbar("Votre beauty artist ne peut plus prendre de RDV pour le moment, contactez le.")
        }
    }

    const toggleDrawer = async () => {
        //setDrawerIsOpen((prev) => !prev);
        setDrawerIsOpen((prev) => !prev);
    };

    const handleOpenAddressInGmaps = (address) => {
        if (address.length>1){
            window.open("https://www.google.com/maps/place/" + address);
        }
    }

    const cancelPendingRdv = async () => {
        setIsContinueDialogOpen(false);
        const depositResult = loadFromLocalStorage("depositResult");
        if (depositResult){
            setShowBackdrop(true);
            try {
                await remTempRes({
                    tempId:depositResult.docId
                })
            } catch (e){
                console.error(e);
            }
            cleanLocalStorage();
            setShowBackdrop(false);
            await handleRdv();
        }
    }

    return (
        <div className="sub-container">
            {displayPage===null ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{borderRadius:"15px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                        <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                displayPage ? (
                    <div className="sub-container-no-top">
                        <div style={{display:"flex", width:"100%"}}>
                            <span className="title-style-presta">{prestaData.title}</span>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", marginTop:"10px", width:"100%"}}>
                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5"}}>
                    <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                        {prestaData.price}
                    </span>
                            </div>
                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", marginLeft:"10px"}}>
                    <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                        {durationFormatMin(prestaData.prestaDuration)}
                    </span>
                            </div>
                        </div>

                        <div style={{display:"flex", width:"100%", marginTop:"20px"}}>
                            {prestaData.imageLinks.length>0 ? <Gallery urls={prestaData.imageLinks}>
                            </Gallery> : <Gallery urls={[{
                                imageLink:"https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..",
                                vlink:""
                            }]}>
                            </Gallery>}

                        </div>

                        <p style={{fontSize:"16px", marginTop:"20px", width:"100%", whiteSpace:"pre-line", wordWrap: "break-word"}}>
                            {prestaData.description}
                        </p>

                        {
                            prestaData?.isDeposit && (
                                <div style={{width:"100%"}}>
                                    <div style={{display: "flex", padding: "20px 20px 20px 20px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start",
                                        gap: "30px", borderRadius: "15px",
                                        background: "linear-gradient(180deg, #DAE0E5 0%, #FFF 100%)",
                                        boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", marginTop:"60px"}}>

                                        <div style={{flexDirection:"column", display:"flex", width:"100%"}}>

                                            <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <span style={{fontWeight: 700, lineHeight: "20px"}}>
                                                    Un acompte sera requis pour valider la réservation.
                                                </span>
                                                <img style={{marginLeft:"20px",marginRight:"7px"}} src={dollaIc}/>
                                            </div>

                                            <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Montant
                                                </span>
                                                <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px"}}>
                                                    {prestaData?.deposit?.amount}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {prestaData?.selectedAddress?.length > 2 && (
                            prestaData?.isAddressVisible ? (
                                <div className="sub-container-no-top">
                                    <div onClick={()=>handleOpenAddressInGmaps(prestaData.selectedAddress)}
                                         style={{
                                             display: "flex",
                                             //background: "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)",
                                             marginTop: "63px",
                                             minHeight: "10px",
                                             borderRadius: "15px",
                                             boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                             width: "100%",
                                             cursor: "pointer",
                                             flexDirection: "column", // Change to column to stack items vertically
                                         }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "1px 20px", // Add padding to create some space around the content
                                                marginBottom:"25px"
                                            }}
                                        >
                                            <div style={{ width: "10%" }}>
                                                <img src={pinMapGrey} style={{width:"30px", height:"30px"}}/>
                                            </div>
                                            <div
                                                style={{
                                                    width: "90%", // Adjusted width to make space for the close icon
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                <span style={{color:"black"}}>{prestaData.selectedAddress}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        lat!==null && long!==null && (
                                            <MapComp latitude={lat} longitude={long} />
                                        )
                                    }

                                </div>

                            ) : (
                                <div
                                     style={{
                                         display: "flex",
                                         //background: "linear-gradient(90deg, #4808FF 0%, #9747FF 100%)",
                                         marginTop: "63px",
                                         minHeight: "10px",
                                         borderRadius: "15px",
                                         boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                         width: "100%",
                                         cursor: "mouse",
                                         flexDirection: "column", // Change to column to stack items vertically
                                     }}
                                >
                                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row", // Change to row to have icon and content side by side
                                            alignItems: "start", // Center icon and content vertically
                                            padding: "1px 20px", // Add padding to create some space around the content
                                            marginBottom:"25px"
                                        }}
                                    >
                                        <div style={{ width: "10%" }}>
                                            <img src={eyeMap} style={{width:"30px", height:"30px"}}/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%", // Adjusted width to make space for the close icon
                                                marginLeft: "10px",
                                            }}
                                        >
                                            <span style={{color:"black"}}>L’adresse de cette prestation est masquée et sera révélée par le beauty artiste à la validation de la réservation.</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        <div style={{display:"flex"}}>
                            <ContactDrawer urlPerso={userObject.urlPerso} isOpen={drawerIsOpen} onClose={toggleDrawer} socials={userObject.socialLinks}/>
                        </div>

                        {prestaData.isAutoBooking ? (<div className="button-container" style={{marginTop:"70px"}}>
                            <Button onClick={handleRdv} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    Prendre RDV
                                </Typography>
                            </Button>
                        </div>) :
                            (
                                <div className="button-container" style={{marginTop:"70px"}}>
                                    <Button startIcon={<img style={{width:"22px", height:"20px", color:"white", marginRight:"4px"}} src={icContact}></img>} onClick={toggleDrawer} className="button-style button-style-noborders" variant="contained">
                                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                            {userObject.pseudo}
                                        </Typography>
                                    </Button>
                                </div>
                            )}


                        <div className="button-container" style={{marginTop:"15px"}}>
                            <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateHome}>
                                <Typography variant="button" style={{ textTransform: 'none' }}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>

                        <Dialog open={isContinueDialogOpen} onClose={()=> setIsContinueDialogOpen(false)}
                                fullWidth={!isLargeScreen}
                                PaperProps={{
                                    style: {
                                        minWidth: isLargeScreen ? '500px' : 'auto',
                                        maxWidth: isLargeScreen ? '500px' : 'auto'
                                    },
                                }}>
                            <DialogContent style={{backgroundColor:"white"}}>
                                <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                    <span style={{fontSize:"18px", lineHeight: "20px", marginTop:"20px"}}>Si vous souhaitez prendre un nouveau rendez-vous, nous annulerons la réservation avec acompte en cours.</span>
                                </div>
                            </DialogContent>
                            <DialogActions style={{backgroundColor:"white"}}>
                                <div className="button-container" style={{marginBottom:"20px",marginTop:"10px", marginLeft:"30px", marginRight:"30px"}}>
                                    <Button disableElevation={true} onClick={()=> cancelPendingRdv()} className="button-style-borders" variant="contained">
                                        <Typography variant="button" style={{ textTransform: 'none', color:'black', fontWeight:400, fontSize:"16px" }}>
                                            Continuer et annuler
                                        </Typography>
                                    </Button>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={showBackdrop}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                            alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            Cette page n'existe pas  🤷
                                        </span>
                        </div>
                    </div>
                )
            )}
        </div>
    )

}