import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {onAuthStateChanged, signInAnonymously} from "firebase/auth";
import {
    createCalendarEvent,
    depTempToRdv,
    firebaseAuth,
    handleImageUpload, remTempRes, tempDepRecov,
    updateDepositRdv,
    updateRdv
} from "../firebase";
import {
    Backdrop,
    CircularProgress, Dialog, DialogActions,
    DialogContent,
    Skeleton,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import ImageCarousel from "./carousel";
import SnackbarWithCustomBackground from "./snackbar";
import ReactGA from "react-ga4";
import {CountdownTimer} from "./timerDeposit";
import {FaPaypal} from "react-icons/fa6";
import icLydia from "../assets/iconLydiaWhite.svg";
import icPaylib from "../assets/iconPaylibWhite.svg";
import {FaGlobe, FaStripe} from "react-icons/fa";
import icSumUp from "../assets/iconSumUpWhite.svg";
import icWise from "../assets/iconWiseWhite.svg";
import icRevolut from "../assets/iconRevolutWhite.svg";
import IconButton from "@mui/material/IconButton";
import {cleanLocalStorage, loadFromLocalStorage, saveToLocalStorage} from "./utils";

function buildRecoverState(recoveryResult){
    const selectedTime = recoveryResult.rdvTempDepositDoc.timeStr;
    const selectedDate = recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[0]+" "+recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[1]
        +" "+recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[2]+" "+recoveryResult.rdvTempDepositDoc.dateStr.split(" ")[3];
    const depositResult = {
        date : new Date(recoveryResult.rdvTempDepositDoc.timestamp._seconds*1000).toISOString(),
        docId : recoveryResult.rdvTempDepositDoc.documentId,
        endDateClient : new Date(recoveryResult.rdvTempDepositDoc.endDateClient._seconds*1000).toISOString(),
        start : new Date(recoveryResult.rdvTempDepositDoc.start._seconds*1000).toISOString(),
        success : true
    }
    const agendaObject = recoveryResult.agendaDoc;
    const userObject = recoveryResult.userDoc;
    let dbObj = recoveryResult.rdvTempDepositDoc;
    dbObj.end = new Date(dbObj.end._seconds * 1000).toISOString();
    dbObj.endDateClient =  new Date(dbObj.endDateClient._seconds * 1000).toISOString();
    dbObj.start =  new Date(dbObj.start._seconds * 1000).toISOString();
    dbObj.timestamp =  new Date(dbObj.timestamp._seconds * 1000).toISOString();
    const prestaId = recoveryResult.rdvTempDepositDoc.prestaSnapshot.documentId;
    const formData = {
        email : recoveryResult.rdvTempDepositDoc.custAddress,
        nom : recoveryResult.rdvTempDepositDoc.custName,
        phone : recoveryResult.rdvTempDepositDoc.custPhone
    }
    const prestaObject = recoveryResult.rdvTempDepositDoc.prestaSnapshot;
    let tempArray =  recoveryResult.rdvTempDepositDoc.answers;

    //stays in the cache but not used until page refreshed, will be pulled from cache at this time
    saveToLocalStorage("prestaId", prestaId);
    //needs to be appended to vars for the page display
    saveToLocalStorage("depositResult", depositResult);
    saveToLocalStorage("formData", formData);
    saveToLocalStorage("dbObject", dbObj);
    saveToLocalStorage("agendaObject", agendaObject);
    saveToLocalStorage("userObject", userObject);
    saveToLocalStorage("selectedTime", selectedTime);
    saveToLocalStorage("selectedDate", selectedDate);
    saveToLocalStorage("answers", tempArray);
    return {prestaObject:prestaObject, formData:formData, dbObj:dbObj, userObject:userObject, agendaObject:agendaObject,
        depositResult:depositResult, selectedDate:selectedDate, selectedTime:selectedTime, answers:tempArray};
}
export default function RdvQuestions() {

    const { id, id2 } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const location = useLocation();
    const [displayPage, setDisplayPage] = useState(null);

    const stateData = location?.state;
    const [userObject, setUserObject] = useState(loadFromLocalStorage("userObject")?.urlPerso===id ? loadFromLocalStorage("userObject") : null);
    const [dbObj, setDbObj] = useState(stateData?.dbObject ? stateData?.dbObject : loadFromLocalStorage("dbObject"));
    const [answers, setAnswers] = useState(loadFromLocalStorage("answers") ? loadFromLocalStorage("answers") : []);
    const [depositResult, setDepositResult] = useState( loadFromLocalStorage("depositResult") ?  loadFromLocalStorage("depositResult") : null);

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [isDepositDialogOpen, setIsDepositDialogOpen] = useState(false);
    const [isAlreadyValidated, setIsAlreadyValidated] = useState(false);

    const [depRef, setDepRef] = useState("");
    const [lockFields, setLockFields] = useState(false);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = onAuthStateChanged(  firebaseAuth,  async ( user) => {
            if (!(user && user.isAnonymous)) {
                try {
                    await signInAnonymously(firebaseAuth);
                } catch (e) {
                    console.log("Auth error => ", e.message);
                    setDisplayPage(false);
                }
            }
            if (user){

                const idRec = new URLSearchParams(location.search).get('idRec');

                if (idRec) {
                    async function fetchData() {
                        try {
                            const recoveryResult = await tempDepRecov({id:idRec});
                            if (recoveryResult?.data?.isBooked){
                                handleOpenSnackbar("Vous avez déjà validé ce rendez-vous");
                                setIsAlreadyValidated(true);
                                setDisplayPage(false);
                            } else if (recoveryResult?.data?.success && recoveryResult.data.userDoc.urlPerso===id
                                && recoveryResult.data.rdvTempDepositDoc.prestaSnapshot.documentId===id2){
                                const valuesForDisplay = buildRecoverState(recoveryResult.data);
                                setDepositResult(valuesForDisplay.depositResult);
                                setUserObject(valuesForDisplay.userObject);
                                setDbObj(valuesForDisplay.dbObj);
                                setAnswers([...valuesForDisplay.answers]);
                                setLockFields(true);
                                setDisplayPage(true);
                            } else {
                                setDisplayPage(false);
                            }
                        } catch (error) {
                            console.error(error);
                            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.")
                            setDisplayPage(false);
                        }
                    }
                    await fetchData();
                } else if (dbObj!=null && userObject!=null){
                    const localId = loadFromLocalStorage("prestaId");
                    if (depositResult!=null && localId===id2){
                        setLockFields(true);
                        setDisplayPage(true);
                    } else {
                        let tempArray = [];
                        for (let q of dbObj.prestaSnapshot.questions){
                            let answerElem = {checked: q.checked || false, question: q.question, answer: "", images: [] };
                            tempArray.push(answerElem);
                        }
                        setAnswers([...tempArray]);
                        setDisplayPage(true);
                    }
                } else {
                    setDisplayPage(false);
                }
            }
        });

        return () => {
            unregisterAuthObserver();
        }
    }, []);

    useEffect( () => {
        if (displayPage && depositResult) {
            (async () => {
                await handleConfirm();
            })();
        }
    }, [displayPage, depositResult]);

    const updateAnswer = (index, newAnswer, question) => {
        // Create a copy of the state array
        const updatedArray = [...answers];
        // Update the answer field at the specified index
        if (updatedArray[index]) {
            // Update the existing answer field
            updatedArray[index].answer = newAnswer;
        } else {
            // Create a new object with the answer field
            updatedArray[index] = {question: question, answer: newAnswer, images: [] };
        }
        // Update the state with the modified array
        setAnswers(updatedArray);
    };

    const handleAddTempImage = (event, index, question) => {
        const file = event.target.files[0];
        if (file) {
            // Create a copy of the state array
            const updatedArray = [...answers];
            // Ensure the data object at dataIndex exists
            if (!updatedArray[index]) {
                updatedArray[index] = { answer: '', images: [] };
            }
            // Push a new image object to the images array
            if (updatedArray[index].images.length>3){
                handleOpenSnackbar("Vous ne pouvez pas ajouter plus de 4 images par question.")
                return;
            }
            updatedArray[index].images.push({ file: file, url: URL.createObjectURL(file) });
            setAnswers(updatedArray);
        }

    };

    const handleDeleteImage = (dataIndex, imageIndex) => {
        // Create a copy of the state array
        const updatedArray = [...answers];

        // Ensure the data object at dataIndex exists
        if (updatedArray[dataIndex]) {
            // Check if the images array exists within the data object
            if (updatedArray[dataIndex].images) {
                // Remove the image at the specified imageIndex
                updatedArray[dataIndex].images.splice(imageIndex, 1);
            }
        }

        // Update the state with the modified array
        setAnswers(updatedArray);

    }

    const navigateBack = async () => {
        if (depositResult){
            setShowBackdrop(true);
            handleOpenSnackbar("Annulation de votre réservation en cours");
            try {
                await remTempRes({
                    tempId:depositResult.docId
                })
            } catch (e){
                console.log(e);
            }
            cleanLocalStorage();
            setShowBackdrop(false);
            navigate('/'+id);
            return;
        }
        navigate(-1);
    }

    const handleConfirm = async () => {

        setShowBackdrop(true);
        if (depositResult){
            const now = new Date().getTime();
            const endTime = new Date(depositResult.date).getTime() + 30 * 60 * 1000;
            const distance = endTime - now;
            if (distance <= 0) {
                handleOpenSnackbar("Le délai pour réserver ce créneau a expiré, veuillez recommencer le processus de réservation.");
                setShowBackdrop(false);
                return;
            }
            setIsDepositDialogOpen(true);
            // either return or open dialog if time is still valid to confirm RDV

            //handleOpenSnackbar("Vous devez entrer le nom de votre client");

            setShowBackdrop(false);
            return;
        }

        cleanLocalStorage();
        for (let answer of answers){
            if (answer.images.length<1 && answer.checked){
                setShowBackdrop(false);
                handleOpenSnackbar("Vous devez ajouter une ou plusieurs images lorsque cela est demandé.");
                return;
            }
            if (answer.answer.length<1){
                setShowBackdrop(false);
                handleOpenSnackbar("Vous devez répondre à toutes les questions avant de confirmer.");
                return;
            }
        }

        if (!dbObj) {
            handleOpenSnackbar("Quelque chose s'est mal passé, veuillez recommencer.");
            setShowBackdrop(false);
            return;
        }

        for (let answer of answers) {
            if (answer?.images?.length > 0) {
                for (let i = 0; i < answer.images.length; i++) {
                    const image = answer.images[i];
                    try {
                        const uploadedUrl = await handleImageUpload(image.file);

                        // Update the image object with the uploaded URL and remove the 'file' property.
                        answer.images[i] = { url: uploadedUrl };

                        // If you want to remove the 'file' property, you can do it like this:
                        // delete answer.images[i].file;
                    } catch (error) {
                        // Handle any errors that occur during the upload process.
                        console.error("Error uploading image:", error);
                    }
                }
            }
        }

        const updatedDbObject = { ...dbObj };
        updatedDbObject["answers"] = answers;

        let docId = ""
        try {
            const currentDate = new Date(updatedDbObject.year, updatedDbObject.month, updatedDbObject.day);
            const offsetInMinutes = currentDate.getTimezoneOffset();
            const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
            const adjustedDate = new Date(currentDate.getTime() + Math.abs(offsetInMilliseconds));

            let result;
            if (updatedDbObject.prestaSnapshot?.isDeposit){
                result = await updateDepositRdv({dataToUpdate:updatedDbObject, inputDate:adjustedDate});
            } else {
                result = await updateRdv({dataToUpdate:updatedDbObject, inputDate:adjustedDate});
            }

            if (result?.data?.success){
                docId = result.data.docId;
                ReactGA.event({
                    category: "rdvclient",
                    action: "validate",
                    value: 1,
                    nonInteraction: true,
                    transport: "xhr",
                });
            } else if (result?.data?.docId==="blocked"){
                handleOpenSnackbar("Ce créneau a été réservé à l'instant, essayez de choisir un autre créneau.");
                setShowBackdrop(false);
                return;
            } else {
                handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous.");
                setShowBackdrop(false);
                return;
            }

            if (updatedDbObject.prestaSnapshot?.isDeposit){
                setDepositResult(result.data);
                saveToLocalStorage("depositResult", result.data);
                saveToLocalStorage("answers", answers);
                saveToLocalStorage("prestaId", id2);
                saveToLocalStorage("dbObject", dbObj);
                saveToLocalStorage("userObject", userObject)
                setLockFields(true);
                setShowBackdrop(false);
                openDepositDialog();
                return;
            }

        } catch (e){
            console.log("Error => ", e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous.");
            setShowBackdrop(false);
            return;
        }

        if (dbObj.isGoogle){
            try {
                //needs to be base32 encoded to be added as calendarId - this allow binding to "rdv" collection

                const encodedId = Array.from(new TextEncoder().encode(String(docId)))
                    .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                    .join('')
                    .toLowerCase();

                // That is a way to decode, case preserved, for later use

                const decodedId = new TextDecoder().decode(
                     new Uint8Array(encodedId.match(/.{1,2}/g).map(chunk => parseInt(chunk, 32)))
                 );

                let questionsString = "";
                if (Array.isArray(updatedDbObject?.answers)) {
                    for (let answer of updatedDbObject?.answers){
                        questionsString += "Question : " + (answer?.question || "N/A") + "\n" +
                            "Réponse : " + (answer?.answer || "N/A") + "\n";
                    }
                }

                await createCalendarEvent({
                    summary: dbObj.prestaSnapshot.title,
                    startDateTime: new Date(dbObj.start).toISOString(),
                    endDateTime: new Date(dbObj.endDateClient).toISOString(),
                    docId : encodedId.toLowerCase(),
                    uid : dbObj.uid,
                    description: "Client : " + dbObj.custName + "\n" +
                        "Email : " + dbObj.custAddress + "\n" +
                        "Téléphone : " + dbObj.custPhone + "\n" +
                        "Page de réservation : https://my.faismoibg.com/rdvdetails/" + docId + " \n" +
                        "Prix : " + dbObj.prestaSnapshot.price + "\n" + questionsString

                })
            } catch (e) {
                console.log(e.message);
                console.error(e);
            }
        }

        setShowBackdrop(false);
        navigate("/"+id+"/rdvstatus/"+docId);

    }


    const handleDepositDialogCloseOutside = () => {
        setIsDepositDialogOpen(false);
    };

    const handleDepositValidated = async () => {

        const now = new Date().getTime();
        const endTime = new Date(depositResult.date).getTime() + 30 * 60 * 1000;
        const distance = endTime - now;
        if (distance <= 0) {
            handleOpenSnackbar("Le délai pour réserver ce créneau a expiré, veuillez recommencer le processus de réservation.");
            setShowBackdrop(false);
            return;
        }
        if (depRef.length<2){
            handleOpenSnackbar("Vous devez ajouter la référence de votre acompte pour continuer.");
            setShowBackdrop(false);
            return;
        }

        if (!depositResult?.docId || depositResult?.docId?.length<3){
            handleOpenSnackbar("Quelque chose s'est mal passé, veuillez recommencer.");
            setShowBackdrop(false);
            return;
        }
        setShowBackdrop(true);
        setIsDepositDialogOpen(false);
        try {
            await depTempToRdv({dataToSpread:{
                    depositRef : depRef, docId : depositResult.docId
                }});
        } catch (e) {
            console.log(e.message);
            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous.");
            cleanLocalStorage();
            setShowBackdrop(false);
            navigate("/"+id);
            return;
        }

        if (dbObj.isGoogle){
            try {
                //needs to be base32 encoded to be added as calendarId - this allow binding to "rdv" collection

                const encodedId = Array.from(new TextEncoder().encode(String(depositResult.docId)))
                    .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                    .join('')
                    .toLowerCase();

                // That is a way to decode, case preserved, for later use
                /*
                const decodedId = new TextDecoder().decode(
                     new Uint8Array(encodedId.match(/.{1,2}/g).map(chunk => parseInt(chunk, 32)))
                 );
                 console.log("decoded => ", decodedId);*/

                let questionsString = "";
                if (Array.isArray(answers)) {
                    for (let answer of answers){
                        questionsString += "Question : " + (answer?.question || "N/A") + "\n" +
                            "Réponse : " + (answer?.answer || "N/A") + "\n";
                    }
                }

                await createCalendarEvent({
                    summary: dbObj.prestaSnapshot.title,
                    startDateTime: new Date(dbObj.start).toISOString(),
                    endDateTime: new Date(dbObj.endDateClient).toISOString(),
                    docId : encodedId.toLowerCase(),
                    uid : dbObj.uid,
                    description:  "Client : " + dbObj.custName + "\n" +
                        "Email : " + dbObj.custAddress + "\n" +
                        "Téléphone : " + dbObj.custPhone + "\n" +
                        "Page de réservation : https://my.faismoibg.com/rdvdetails/" + depositResult.docId + " \n" +
                        "Prix : " + dbObj.prestaSnapshot.price + "\n" +
                        "Référence acompte : " + (depRef || "NA") + "\n" + questionsString
                })
            } catch (e) {
                console.log(e.message);
            }
        }

        navigate("/"+id+"/rdvstatus/"+depositResult.docId);
    };

    const openDepositDialog =  () => {
        setIsDepositDialogOpen(true);
    }

    const updateDepRef =  (event) => {
        setDepRef(event.target.value);
    }


    return (
        <div className="sub-container">

            {displayPage===null ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{marginTop:"10px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
            ) : (
                displayPage ? (
                    <div className="sub-container-no-top">
                        <span className="title-style" style={{textAlign:"center"}}>Informations complémentaires</span>
                        <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", whiteSpace:"pre-line"}}>
                            {dbObj.prestaSnapshot.intro}
                        </span>
                        {answers.map((object,index)=> (
                            <div style={{display:"flex", flexDirection:"column", width:"100%", marginTop:"50px"}} key={index}>
                                <span style={{color:"#4808FF", fontSize:"16px", lineHeight:"24px", fontWeight:400}}>
                                    Question {index + 1} <span className="aster">*</span>
                                </span>
                                <span style={{color:"#17181A", fontSize:"16px", lineHeight:"24px", fontWeight:700, marginTop:"20px"}}>
                                    {object?.question}
                                </span>
                                <div style={{width:"100%", display:"flex", flexDirection:"row", marginTop:"14px" }}>
                                    <TextField className="field-style" label="Ecrire"  variant="outlined" disabled={lockFields} value={object.answer}
                                               onChange={(evt) => updateAnswer(index, evt.target.value, object?.question)} />
                                </div>
                                {
                                    object.checked && (
                                        <div>
                                            <div style={{marginTop:"20px"}}>
                                                <Button disabled={lockFields} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                                    <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px" }}>
                                                        Insérez une photo
                                                    </Typography>
                                                    <input accept="image/jpeg,image/png,image/gif,image/svg+xml"
                                                           id={"file-input" + index} style={{ display: 'none' }} type="file"
                                                           onChange={(event) => handleAddTempImage(event, index)}
                                                    />
                                                </Button>
                                            </div>
                                            <div style={{marginTop:"15px", display:"flex", width:"100%"}}>
                                                {object?.images?.length<1 ?
                                                    <div>Aucune image séléctionnée</div> : <ImageCarousel style={{marginTop:"10px"}} imagesArray={object?.images} blockButton={lockFields}
                                                                                                          onDeleteImage={(index2) => handleDeleteImage(index, index2)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        ))}

                        <div className="button-container" style={{marginTop:"80px"}}>
                            <Button onClick={() => handleConfirm()} className="button-style button-style-noborders" variant="contained">
                                <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                    {dbObj?.prestaSnapshot?.isDeposit ? "Régler acompte pour réserver" : "Confirmer votre réservation"}
                                </Typography>
                            </Button>
                        </div>

                        <div className="button-container" style={{marginTop:"15px"}}>
                            <Button style={{marginBottom:"50px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                                <Typography variant="button" style={{ textTransform: 'none' }}>
                                    Retour
                                </Typography>
                            </Button>
                        </div>
                        <span style={{width:"100%", marginBottom:"50px"}}>
                            En continuant vous confirmez avoir pris connaissance de <a href="https://shine.faismoibg.com/politique-confidentialite" target="_blank" rel="noreferrer">notre politique de confidentialité</a> ainsi que de <a href="https://shine.faismoibg.com/cgu" target="_blank" rel="noreferrer" >nos CGU</a>
                        </span>

                    </div>) : (
                    <div className="sub-container-no-top">
                        <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                            alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            {isAlreadyValidated ? "Vous avez déjà validé ce rendez-vous 🤷" : "Cette page n'existe pas  🤷"}
                                        </span>
                        </div>
                    </div>
                )
            )}

            <Dialog
                open={isDepositDialogOpen}
                onClose={handleDepositDialogCloseOutside}
                fullWidth={!isLargeScreen}
                PaperProps={{
                    style: {
                        minWidth: isLargeScreen ? '500px' : 'auto',
                        maxWidth: isLargeScreen ? '500px' : 'auto',
                    },
                }}
            >
                <DialogContent style={{ backgroundColor: "white" }}>
                    <div style={{display:"flex", flexDirection:"column", padding:"10px 6px"}}>
                        <CountdownTimer dbTimestamp={depositResult?.date}></CountdownTimer>
                        <span style={{fontSize:"16px", lineHeight: "20px", marginTop:"20px"}}>
                            ⚠️ Nous vous avons également envoyé un mail récapitulatif avec un lien vous permettant de revenir sur cette page afin de valider l’acompte.
                        </span>
                        <span style={{fontSize:"16px", lineHeight: "20px", marginTop:"20px"}}>
                            Afin de valider cette réservation, nous vous invitons à régler cet acompte, en suivant les étapes ci-dessous :
                        </span>
                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Etape 1. <span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>Accédez à la page de paiement via l’îcone ci-dessous.</span>
                        </span>
                        <div style={{ width: "100%", marginTop: "20px", display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "flex-start", flexWrap:"wrap" }}>
                                {dbObj?.prestaSnapshot?.deposit?.paymentLinks.map((socialLink, index) => {
                                    if (socialLink?.link?.length > 0) {
                                        const iconComponent = {
                                            paypal: <FaPaypal style={{ fontSize: '0.9rem', background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", }} />,
                                            lydia: <img src={icLydia} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px" }} />,
                                            paylib: <img src={icPaylib} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white",width:"14px", height:"14px", objectFit:"cover",scale:"1.2" }} />,
                                            stripe: <FaStripe style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", fontSize: '0.9rem', color: "white", scale:"1.3"  }} />,
                                            sumup: <img src={icSumUp}  style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px", objectFit:"cover"}} />,
                                            wise: <img src={icWise} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px", objectFit:"cover", scale:"1.8" }} />,
                                            revolut: <img src={icRevolut} style={{ background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white", width:"14px", height:"14px", objectFit:"cover", scale:"1.2" }} />,
                                            site: <FaGlobe style={{ fontSize: '0.9rem', background: "linear-gradient(180deg, #FF0844 0%, #4808FF 100%)", borderRadius:"99px", padding:"5px", color:"white" }} />,
                                        }[socialLink?.network];

                                        return (
                                            <a style={{textDecoration:"none"}} href={socialLink.link} target="_blank" key={socialLink?.network+index}>
                                                <Button startIcon={iconComponent} className="button-style-borders" key={index} style={{ padding: "10px 25px", marginTop:"10px" }}>
                                                    <Typography style={{textTransform: 'none', fontSize:"16px", fontWeight:400}}>
                                                        {socialLink.network}
                                                    </Typography>
                                                </Button>
                                            </a>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Etape 2. <span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>Procédez au règlement de l’acompte directement sur la page de paiement.</span>
                        </span>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"start", marginTop:"10px", alignItems:"center"}}>
                            <span>
                                Montant de l’acompte :
                            </span>
                            <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px", marginLeft:"12px"}}>
                                {dbObj?.prestaSnapshot?.deposit?.amount}
                            </div>
                        </div>

                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Etape 3. <span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>Indiquez le numéro de transaction de votre paiement, puis valider l’acompte.</span>
                        </span>

                        <div style={{width:"100%", display:"flex", flexDirection:"row", marginTop:"14px" }}>
                            <TextField className="field-style" label="Numéro de transaction"  variant="outlined"
                                       onChange={(event) => updateDepRef(event)} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "white" }}>
                    <Button className="blue-button button-style-noborders" style={{marginLeft:"32px", marginRight:"32px", marginBottom:"30px", marginTop:"10px", backgroundColor:"black"}} variant="button"
                            onClick={() => {
                                handleDepositValidated(); // Replace indexToDelete with the actual index you want to delete
                            }}
                    >
                        <Typography variant="button" style={{textTransform: 'none', color:"white"}}>
                            Confirmer
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
        </div>
    )
}